import { createRouter, createWebHistory } from 'vue-router';

const routes = [
	{
		path: '/',
		name: 'wallet',
		component: () => import(/* webpackChunkName: "wallet" */ '@/views/Wallet')
	},
	{
		path: '/:transactionID',
		name: 'transaction detail',
		props: true,
		component: () => import(/* webpackChunkName: "transaction" */ '@/views/TransactionDetail')
	},
	{
		path: '/about',
		name: 'about',
		component: () => import(/* webpackChunkName: "about" */ '@/views/About')
	},
	{
		path: '/settings',
		name: 'settings',
		component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings')
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

export default router;
