import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faExternalLink, faCog } from '@fortawesome/pro-light-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faExternalLink, faCog);

createApp(App).component('fa', FontAwesomeIcon).use(store).use(router).mount('#app');
