<template>
	<div class="page-wrapper">
		<nav>
			<div class="nav-title">Sia Foundation Tracker</div>
			<router-link :to="{ name: 'wallet' }">Wallet</router-link>
			<router-link :to="{ name: 'about' }">About</router-link>
			<router-link :to="{ name: 'settings' }"><fa :icon="['fal', 'cog']" /></router-link>
		</nav>
		<router-view v-slot="{ Component }" :key="$route.fullPath">
			<transition :css="false" v-on:enter="onEnter" v-on:leave="onLeave" appear>
				<component :is="Component" />
			</transition>
		</router-view>
	</div>
</template>

<script>
import { TweenMax, TimelineMax, Power4 } from 'gsap';

export default {
	methods: {
		onEnter(el, done) {
			const tl = new TimelineMax({
				onComplete: done
			});

			tl.set(el, {
				x: window.innerWidth * 1.5,
				scale: 0.8,
				transformOrigin: '50% 50%'
			});

			tl.to(el, {
				duration: 0.5,
				x: 0,
				ease: Power4.easeOut
			});

			tl.to(el, {
				duration: 1,
				scale: 1,
				ease: Power4.easeOut
			});
		},
		onLeave(el, done) {
			TweenMax.fromTo(el, 1, {
				autoAlpha: 1
			}, {
				autoAlpha: 0,
				ease: Power4.easeOut,
				onComplete: done
			});
		}
	}
};
</script>

<style lang="stylus">
@require "styles/global.styl";

#app {
	width: 100%;
	height: 100%;
}

nav {
	position: fixed;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 60px;
	top: 0;
	left: 0;
	right: 0;
	background: darken(bg, 10%);
	border-bottom: 1px solid #2d2d2d;
    box-shadow: 0 2px 7px 2px rgb(0 0 0 / 54%);
	z-index: 999;

	.nav-title {
		flex: 1;
		padding: 0 15px;
		font-size: 1.1rem;
		color: primary;
	}

	a {
		display: inline-block;
		padding: 0 15px;
		color: rgba(255, 255, 255, 0.84);
		text-decoration: none !important;

		&.router-link-active, &:hover, &:focus, &:active {
			color: primary;
		}
	}
}

.page-wrapper {
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
}
</style>
