import { getExchangeRates } from '@/api';

import { createStore } from 'vuex';

const store = createStore({
	state: {
		exchangeRates: {},
		currencyCode: localStorage.getItem('displayCurrency') || 'usd'
	},
	mutations: {
		setExchangeRates(state, rates) {
			state.exchangeRates = rates;
		},
		setCurrencyCode(state, code) {
			state.currencyCode = code;
		}
	},
	actions: {
		setExchangeRates({ commit }, rates) {
			commit('setExchangeRates', rates);
		},
		setCurrencyCode({ commit }, code) {
			commit('setCurrencyCode', code);
			localStorage.setItem('displayCurrency', code);
		}
	},
	modules: {
	}
});

async function loadExchangeRates() {
	store.dispatch('setExchangeRates', await getExchangeRates());
}

loadExchangeRates();

export default store;