
export async function getFoundationWallet(currency = 'usd') {
	const resp = await fetch(`https://api.siacentral.com/v2/explorer/foundation/wallet?currency=${currency}`),
		body = await resp.json();

	if (resp.status !== 200)
		throw new Error(body?.message || 'unable to get block height');

	return body;
}

export async function getCurrentBlockHeight() {
	const resp = await fetch('https://api.siacentral.com/v2/explorer/blocks'),
		body = await resp.json();

	if (resp.status !== 200)
		throw new Error(body?.message || 'unable to get block height');

	return body?.block?.height || 0;
}

export async function getExchangeRates() {
	const resp = await fetch('https://api.siacentral.com/v2/market/exchange-rate?currencies=sc'),
		body = await resp.json();

	if (resp.status !== 200)
		throw new Error(body?.message || 'unable to get exchange rates');

	return body?.rates?.sc;
}

export async function getFoundationTransaction(id, currency = 'usd') {
	const resp = await fetch(`https://api.siacentral.com/v2/explorer/foundation/wallet/${id}?currency=${currency}`),
		body = await resp.json();

	if (resp.status !== 200)
		throw new Error(body?.message || 'unable to get transaction');

	return body.transaction;
}